<template>
  <a-modal
    class="global-range-modal"
    centered
    :visible="visible"
    :width="655"
    @cancel="handledClose('close')"
    @ok="handledClose('ok')"
  >
    <a-row type="flex" class="model-row">
      <a-col :span="12" class="model-col">
        <!-- search -->
        <div class="model-search">
          <a-input
            class="model-input"
            v-model:value.trim="seachVal"
            :placeholder="placeholder"
            @change="toChange"
            allow-clear
          >
            <template #prefix>
              <SearchOutlined style="color: rgba(0, 0, 0, 0.25)" />
            </template>
          </a-input>
        </div>
        <!-- 面包屑 -->
        <div class="model-breadcrumb" v-if="type !== 4">
          <a-breadcrumb>
            <template #separator><span style="color: black">></span></template>

            <a-breadcrumb-item
              href=""
              v-for="(item, index) in breadcrumbList"
              @click="handledBreadcrumb(item, index)"
              :key="index"
            >
              <a-tooltip placement="topRight" :title="item.label" v-if="item.label.length > 8">
                {{ item.label }}
              </a-tooltip>
              <div v-else>
                {{ item.label }}
              </div>
            </a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <!-- 全选 -->
        <span
          :class="[
            'all-sel',
            {
              'all-sel-disabled': isSelectedFlag()
            }
          ]"
          @click="toAllSel"
          v-if="checkAll"
        >
          全选
        </span>
        <p v-if="type === 4" class="subtile">审批流程</p>
        <!-- 待选择列表 -->
        <div class="left-list">
          <template v-if="renderList.length">
            <div v-for="item in renderList" :key="item.id" class="model-item">
              <a-checkbox
                v-if="type === 2 || item.dataType === 1 || type === 4 || type === 11"
                :checked="getCheckState(item)"
                class="model-checkbox"
                @change="handledChange(item)"
              >
                <div class="model-checkbox-item">
                  <img class="model-checkbox-img" :src="item.icon" />
                  <div class="model-checkbox-name single-wrap">
                    <a-tooltip placement="topLeft" :title="item.dataName" v-if="item.dataName.length > 8">
                      {{ item.dataName }}
                    </a-tooltip>
                    <div v-else>
                      {{ item.dataName }}
                    </div>
                  </div>
                </div>
              </a-checkbox>
              <div v-else class="model-checkbox">
                <div class="model-checkbox-item">
                  <img class="model-checkbox-img" :src="item.icon" />
                  <div class="model-checkbox-name single-wrap">
                    <a-tooltip placement="topLeft" :title="item.dataName" v-if="item.dataName.length > 8">
                      {{ item.dataName }}
                    </a-tooltip>
                    <div v-else>
                      {{ item.dataName }}
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="item.islower" class="model-lower-level" @click.stop="tolowerMdel(item.departmentId, item, '')">
                <span class="model-title">下级</span>
                <RightOutlined />
              </div>
            </div>
          </template>
          <a-empty v-else :image="simpleImage" />
        </div>
      </a-col>
      <!-- 已选列表 -->
      <a-col :span="12" class="model-col model-right">
        <div class="flex-layout" style="justify-content: space-between">
          <h4>已选：{{ checkedStaff.length }} 个</h4>
          <span class="checked-progress" v-if="checkAllLimit">{{ checkedStaff.length }}/{{ checkAllLimit }}</span>
        </div>
        <div class="model-checked" v-if="checkedStaff.length">
          <a-row v-for="(item, index) in checkedStaff" :key="item.id" class="model-checked-row">
            <a-col :span="22" style="padding-right: 20px; display: flex" class="single-wrap">
              <img class="model-checked-img" :src="item.icon" />
              <div class="model-checkbox-name single-wrap">
                <a-tooltip placement="topLeft" :title="item.dataName" v-if="item.dataName.length > 8">
                  {{ item.dataName }}
                </a-tooltip>
                <div v-else>
                  {{ item.dataName }}
                </div>
              </div>
            </a-col>
            <a-col :span="2" @click="handledDelete(item, index)" class="model-close">
              <CloseCircleFilled />
            </a-col>
          </a-row>
        </div>
        <a-empty v-else :image="simpleImage" style="margin-top: 150px" />
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import { reactive, toRefs, onMounted, ref, onUnmounted, defineComponent, watch, watchEffect } from 'vue'
import { RightOutlined, CloseCircleFilled, SearchOutlined } from '@ant-design/icons-vue'
import { getscopelower, getSearchData, getapproval, getDepartmentOrStaff } from '@/apis/businessManage'
import { Empty } from 'ant-design-vue'
import { useStore } from 'vuex'
import { cmsNotice } from '@/utils/utils'
export default defineComponent({
  name: 'RangeModal',
  props: {
    selectData: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Boolean
    },
    type: {
      //1:数据查看人/流程名称负责人/主管,2/3:添加成员/部门或人员,3:抄送人/补盖审批人,4:审批流程,5:特权使用人,6:印章保管员,10:角色人员，11.调整部门/所属组织
      type: Number
    },
    // 全选
    checkAll: {
      type: Boolean
    },
    checkAllLimit: {
      required: false,
      type: Number
    }
  },
  components: {
    CloseCircleFilled,
    SearchOutlined,
    RightOutlined
  },
  emits: ['close', 'checkData'],
  setup(props, { emit }) {
    const state = reactive({
      seachVal: '', // 搜索关键词
      checkedStaff: [],
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      renderList: [],
      breadcrumbList: []
    })
    const usedDataList = ref()
    const store = useStore()
    const time = ref(null)
    const placeholder = ref('')
    const selectorType = ref(1)

    // 获取左list单项选中状态，判断右列表是否存在该数据
    const getCheckState = item => {
      return state.checkedStaff.findIndex(it => it.dataType === item.dataType && it.dataId === item.dataId) >= 0
    }

    // 切换选中状态，在右列表中删掉或增加数据，选中状态还是根据 getCheckState() 判断
    const handledChange = e => {
      const checked = getCheckState(e)
      console.log('选中的', checked)
      if (checked) {
        const index = state.checkedStaff.findIndex(it => it.dataType === e.dataType && it.dataId === e.dataId)
        state.checkedStaff.splice(index, 1)
      } else {
        if (props.checkAllLimit && state.checkedStaff.length === props.checkAllLimit)
          return cmsNotice('warning', '当前已添加至人员上限请删除已选人员后重试')
        console.log('e', e)
        state.checkedStaff.push({
          dataName: e.dataName,
          dataType: e.dataType,
          dataId: e.dataId,
          icon: e.icon
        })
      }
    }
    const handledBreadcrumb = (e, i) => {
      state.breadcrumbList.splice(i + 1, state.breadcrumbList.length - i)
      if (e.label === store.state.user.userInfo.result.enterpriseName) {
        getScopeList()
        return
      }
      tolowerMdel(e.id, '', 'jump')
    }
    const handledDelete = (e, i) => {
      state.checkedStaff.splice(i, 1)
    }
    //搜索
    const toChange = e => {
      if (time.value) {
        clearTimeout(time.value)
      }
      time.value = setTimeout(() => {
        let data = e.target.value
        console.log('搜索输入内容data', data)
        state.breadcrumbList.splice(1, state.breadcrumbList.length)
        if (data === '') {
          if (props.type !== 4) {
            getScopeList()
          } else {
            getApprovalList('')
          }
          return
        }
        searchData(data)
      }, 800)
    }
    //搜索请求
    const searchData = async data => {
      if (props.type !== 4) {
        let params = {
          selectorType: selectorType.value,
          searchName: data
        }
        const res = await getSearchData(params)
        console.log('搜索到的值res', res)
        handelData(res)
      } else {
        //审批流程
        const res = await getapproval({ flowName: data })
        console.log('res', res)
        formatApprovalProcessList(res.data)
      }
    }
    // 确定or取消
    const handledClose = type => {
      // getScopeList()
      state.seachVal = ''
      type === 'ok' && emit('checkData', [...state.checkedStaff])
      emit('close', false)
    }
    //初始列表
    const getScopeList = async data => {
      // const res = await getscope({ sealId: '' })
      let params = {
        selectorType: selectorType.value //1部门人员2部门3员工
      }
      // console.log('拼接后的参数', { ...params, ...data })
      const res = await getDepartmentOrStaff({ ...params, ...data })
      if (res.success) {
        handelData(res)
      }
    }
    // 印章适用范围子级
    const tolowerMdel = async (id, val, flg) => {
      let data = {
        departmentId: id
      }
      getScopeList(data)
      // const res = await getscopelower({ departmentId: id })
      // handelData(res)
      if (flg === 'jump') return
      state.breadcrumbList.push({
        label: val.departmentName,
        id: val.departmentId
      })
      console.log('-----123----41124-----', state.breadcrumbList)
    }

    // 处理左侧数据
    const handelData = res => {
      let department = []
      let personnel = []
      if (res.data.departmentList && res.data.departmentList.length !== 0) {
        department = res.data.departmentList.map(item => {
          item.islower = true
          // item.islower = item.hasChildren //0没有下级,1有下级
          item.dataName = item.departmentName
          item.dataType = 2
          item.dataId = item.departmentId
          item.icon = require('@/assets/images/checkStaff/level.png')
          return item
        })
      }
      if (res.data.staffList && res.data.staffList.length !== 0) {
        personnel = res.data.staffList.map(item => {
          item.islower = false
          // item.islower = item.hasChildren //0没有下级,1有下级
          item.dataName = item.staffName
          item.dataType = 1
          item.dataId = item.staffId
          item.icon = item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')
          // item.identity = item.identity
          return item
        })
      }
      // 角色人员不展示超管
      if (props.type === 10) {
        personnel = personnel.filter(it => it.identity === 2)
      }

      // state.renderList = department.concat(personnel)
      state.renderList = [...department, ...personnel]
      console.log('左侧数据', state.renderList)
    }
    const getPopupContainer = trigger => {
      return trigger.parentElement
    }

    const isAvatar = item => {
      if (item.icon) return item.icon
      if (item.fullAvatar) return item.fullAvatar
      if (item.dataType === 2) return require('@/assets/images/checkStaff/level.png')
      return require('@/assets/images/default-avatar.png')
    }

    // watch(
    //   () => props.visible,
    //   val => {
    //     if (!val) return
    //     state.checkedStaff = []
    //     state.breadcrumbList = [{ label: store.state.user.userInfo.result.enterpriseName }]
    //     props.selectData.forEach(item => {
    //       console.log('props.selectData111111111111', props.selectData)
    //       console.log('state.checkedStaff2222222222', state.checkedStaff)
    //       state.checkedStaff.push({
    //         dataName: item.dataName,
    //         dataType: item.dataType,
    //         dataId: item.dataId,
    //         icon: isAvatar(item)
    //       })
    //     })
    //   },
    //   { immediate: true }
    // ),

    //格式化审批流程列表
    const formatApprovalProcessList = list => {
      let approvalProcess = list.map(item => {
        item.dataName = item.flowName
        item.dataType = item.flowType
        item.dataId = item.flowId
        item.icon = require('@/assets/images/checkStaff/approval-process.png')
        return item
      })
      state.renderList = approvalProcess
    }
    //获取审批流程
    const getApprovalList = async nameValue => {
      const res = await getapproval({ flowName: nameValue })
      console.log('审批流程', res.data)
      formatApprovalProcessList(res.data)
    }
    // 全选
    const toAllSel = () => {
      const flag = isSelectedFlag()
      console.log(flag)
      if (flag) return
      const limitNum = props.checkAllLimit // 限制
      // 没有个数限制 权限
      if (props.checkAll && !limitNum) {
        state.renderList.forEach((e, index) => {
          if (e.islower || getCheckState(e)) return
          state.checkedStaff.push({
            dataName: e.dataName,
            dataType: e.dataType,
            dataId: e.dataId,
            icon: e.icon
          })
        })
        return
      }
      // 个数限制
      const checkedStaffLength = state.checkedStaff.length //  已选的长度
      checkedStaffLength === limitNum && cmsNotice('warning', '当前已添加至人员上限请删除已选人员后重试')
      var personelIndex = 0 //为员工的个数
      if (checkedStaffLength >= limitNum) return
      state.renderList.forEach((e, index) => {
        if (e.islower || getCheckState(e)) return
        personelIndex++
        if (personelIndex <= limitNum - checkedStaffLength) {
          state.checkedStaff.push({
            dataName: e.dataName,
            dataType: e.dataType,
            dataId: e.dataId,
            icon: e.icon
          })
        }
      })
    }
    watchEffect(
      () => {
        if (!props.visible) {
          return
        } else {
          state.renderList = []
          state.checkedStaff = []
          state.breadcrumbList = [{ label: store.state.user.userInfo.result.enterpriseName }]
          if (props.type === 4) {
            //审批流程
            getApprovalList('')
            placeholder.value = '请输流程名称'
          } else {
            selectorType.value = 1
            placeholder.value = '请输入员工姓名或部门'
            if (props.type === 11) {
              selectorType.value = 2
              placeholder.value = '请输入部门名称查询111'
            }
            if (props.type === 5) {
              selectorType.value = 3
              placeholder.value = '请输入姓名查询'
            }
            getScopeList()
          }

          console.log('props.selectData', props.selectData)
          props.selectData.forEach(item => {
            state.checkedStaff.push({
              dataName: item.dataName,
              dataType: item.dataType,
              dataId: item.dataId,
              icon: isAvatar(item)
            })
          })
        }
      }
      // { immediate: true }
    )
    const isSelectedAll = (arr1, arr2) => {
      const arrs = []
      arr2.forEach(item => {
        const data = arr1.find(i => i.dataId === item.dataId)
        data && arrs.push(data)
      })
      return arr2.length ? arrs.length === arr1.length : false
    }
    const isSelectedFlag = () => {
      const flag = props.checkAllLimit
        ? state.checkedStaff.length === props.checkAllLimit
        : isSelectedAll(
            state.renderList.filter(it => it.dataType === 1),
            state.checkedStaff
          )
      return flag
    }

    onMounted(() => {
      // getScopeList()
    })
    onUnmounted(() => {
      clearTimeout(time.value)
    })

    return {
      ...toRefs(state),
      getCheckState,
      handledChange,
      toChange,
      handledBreadcrumb,
      handledDelete,
      handledClose,
      tolowerMdel,
      getPopupContainer,
      placeholder,
      getApprovalList,
      toAllSel,
      isSelectedAll,
      isSelectedFlag,
      selectorType
    }
  }
})
</script>

<style lang="less" scoped>
:deep(.ant-modal-header) {
  border-bottom: 1px solid red !important;
}

.model-row {
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  height: 100%;
  overflow: hidden;

  .model-col {
    padding: 16px 14px;

    .model-search {
      display: flex;

      .model-button {
        margin-left: 20px;
      }
    }

    .model-breadcrumb {
      margin-top: 12px;
    }

    .subtile {
      margin-top: 12px;
    }

    .left-list {
      margin-top: 12px;
      overflow: scroll;
      max-height: 300px;
      padding-bottom: 20px;

      .model-lower-level {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #c3161c;
        cursor: pointer;

        .model-title {
          margin-right: 12px;
        }
      }

      .model-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .model-checkbox {
          flex: 1;
          padding-right: 8px;
          display: flex;
          align-items: center;

          .model-checkbox-item {
            display: flex;
            align-items: center;

            .model-checkbox-img {
              width: 26px;
              height: 26px;
              border-radius: 50%;
              margin-left: 2px;
              margin-right: 8px;
            }

            .model-checkbox-name {
              display: inline-block;
              max-width: 140px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .model-checked {
      max-height: 333px;
      overflow-y: scroll;
      padding-right: 16px;
      margin-top: 26px;

      .model-checked-row {
        margin-bottom: 20px;

        .model-close {
          text-align: right;
          color: #cccccc;
          width: 14px;
          height: 14px;
          cursor: pointer;
        }

        .model-checked-img {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          margin-right: 12px;
          vertical-align: middle;
        }
      }
    }
  }

  .model-right {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  }

  :deep(.ant-breadcrumb a) {
    color: #333333;
    font-size: 12px;
    line-height: 12px;
    max-width: 110px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    direction: rtl;
  }

  :deep(.ant-breadcrumb > span:last-child a) {
    color: #0A7BFF;
    font-size: 12px;
  }
}

.all-sel {
  cursor: pointer;
  color: #2a68e7;
  margin: 5px 0;
  display: inline-block;
}

.all-sel-disabled,
.checked-progress {
  color: #969799;
}
</style>
